const mode = process.env.REACT_APP_ENV;
const API_URL =
  mode === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_LIVE_API_URL;
  
export const env = Object.freeze({
  API_URL,
  // API_URL: "https://edge-pages.ue.r.appspot.com/v1",
  // API_URL: "http://localhost:3003/v1",
});
