import React, { useEffect, useState, useContext } from "react";
import "../../style/home/header.scss";
import { images } from "../../assets";
import { Button, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function Headers() {
  const { t } = useTranslation();
  const navItems = [
    { title: t("home"), url: "/" },
    // { title: "About Us", url: "/aboutUs" },
    { title: t("profile"), url: "/profile" },
    // { title: "Privacy Policy", url: "/privacyPolicy" },
    { title: t("subscriptions"), url: "/pricingTable" },
    { title: t("calendar"), url: "/calendar" },
    { title: t("businesswall"), url: "/businessWall" },
    // { title: "Contact Us", url: "/contactUs" },
    // { title: "Categories", url: "/categories" },
  ];
  const { user, setUser } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavItemClick = () => {
    setMenuOpen(false);
  };

  const navigate = useNavigate();
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen]);

  return (
    <header className="main-navbar">
      <div className="container-navber">
        <div className="main-logo">
          <div className="navbar-brand" onClick={() => navigate("/")}>
            <img
              src={images.logo}
              alt="Edge Logo"
              onClick={() => navigate("/")}
              className="logo"
              style={{ width: "80px" }}
            />
          </div>
        </div>
        <nav className={`navbar-nav ${menuOpen ? "active" : ""}`}>
          <ul className="nav-list">
            {navItems.map((item, index) => (
              <li key={index}>
                <NavLink to={item.url} onClick={handleNavItemClick}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="navbar-actions">
          {user ? (
            <Button
              className="login-in-btn"
              onClick={() => {
                localStorage.removeItem("jwt");
                setUser(null);
                 message.success("logout successfuly");
                navigate("/");
              }}
            >
              {t("logout")}
            </Button>
          ) : (
            <Button className="login-in-btn" onClick={() => navigate("/login")}>
             {t("login")}
            </Button>
          )}
        </div>
        <div className="menu-icon" onClick={handleMenuToggle}>
          <MenuOutlined />
        </div>
      </div>
      {menuOpen && <div className="backdrop" onClick={handleMenuToggle}></div>}
    </header>
  );
}
