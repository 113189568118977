import Layouts from "../layout/Layouts";
import LazyComponent from "./LazyComponent";
// import ManageProfessional from "../screens/ManageProfessional/ManageProfessional";
export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      // <Layouts>
      <LazyComponent path="home/home" />
      // </Layouts>
    ),
    description: "home-Page",
  },
  businessProfile: {
    title: "businessProfileome",
    path: "/businessProfile",
    element: (
      <Layouts>
        <LazyComponent path="businessProfile/BusinessProfile" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },

  BusinessWall: {
    title: "BusinessWall",
    path: "/businessWall",
    element: (
      <Layouts>
        <LazyComponent path="businessWall/BusinessWall" />
      </Layouts>
    ),
    description: "BusinessWall-Page",
  },

  BusinessWallpost: {
    title: "BusinessWall",
    path: "/businessWall/:postId",
    element: (
      <Layouts>
        <LazyComponent path="businessWall/BusinessWall" />
      </Layouts>
    ),
    description: "BusinessWall-Page",
  },
  eventsBusiness: {
    title: "eventsBusiness",
    path: "/eventsBusiness",
    element: (
      <Layouts>
        <LazyComponent path="eventsBusiness/EventsBusiness" />
      </Layouts>
    ),
    description: "eventsBusiness-Page",
  },
  FoodDrictory: {
    title: "FoodDrictory",
    path: "/foodDrictory",
    element: (
      <Layouts>
        <LazyComponent path="FoodDrictory/FoodDrictory" />
      </Layouts>
    ),
    description: "eventsBusiness-Page",
  },
  Categories: {
    title: "Categories",
    path: "/categories",
    element: (
      <Layouts>
        <LazyComponent path="categories/Categories" />
      </Layouts>
    ),
    description: "Categories-Page",
  },
  Chat: {
    title: "Chat Screen",
    path: "/chat/:freelancerId",
    element: (
      <Layouts>
        <LazyComponent path="Chat/Chat" />
      </Layouts>
    ),
    description: "Chat with Freelancer",
  },
  inbox: {
    title: "Chat Screen",
    path: "/chat",
    element: (
      <Layouts>
        <LazyComponent path="Chat/Chat" />
      </Layouts>
    ),
    description: "Chat with Freelancer",
  },

  EdgeBusinessesPage: {
    title: "EdgeBusinessesPage",
    path: "/edgeBusinessesPage",
    element: (
      <Layouts>
        <LazyComponent path="EdgeBusinessesPage/EdgeBusinessesPage" />
      </Layouts>
    ),
    description: "EdgeBusinessesPage-Page",
  },
  Profile: {
    title: "Profile",
    path: "/profile",
    element: (
      <Layouts>
        <LazyComponent path="profile/Profile" />
      </Layouts>
    ),
    description: "Profile-Page",
  },
  paymentSuccess: {
    title: "Payment successfull",
    path: "/payment-success",
    element: (
      <Layouts>
        <LazyComponent path="payment/success" />
      </Layouts>
    ),
    description: "Payment successfull",
  },
  paymentFail: {
    title: "Payment Failed",
    path: "/payment-failed",
    element: (
      <Layouts>
        <LazyComponent path="payment/failed" />
      </Layouts>
    ),
    description: "Payment Failed",
  },
  RestaurantProfile: {
    title: "Restaurant Profile",
    path: "/restaurant-profile",
    element: (
      <Layouts>
        <LazyComponent path="restaurantProfile/Profile" />
      </Layouts>
    ),
    description: "Restaurant Profile",
  },
  ProfessionalProfile: {
    title: "ProfessionalProfile",
    path: "/professionalProfile",
    element: (
      <Layouts>
        <LazyComponent path="ProfessionalProfile/ProfessionalProfile" />
      </Layouts>
    ),
    description: "ProfessionalProfile",
  },
  PricingTable: {
    title: "PricingTable",
    path: "/pricingTable",
    element: (
      <Layouts>
        <LazyComponent path="PricingTable/PricingTable" />
      </Layouts>
    ),
    description: "PricingTable",
  },
  AboutUs: {
    title: "AboutUs",
    path: "/aboutUs",
    element: (
      <Layouts>
        <LazyComponent path="AboutUs/AboutUs" />
      </Layouts>
    ),
    description: "PrivacyPolicy",
  },
  PrivacyPolicy: {
    title: "PrivacyPolicy",
    path: "/privacyPolicy",
    element: (
      <Layouts>
        <LazyComponent path="PrivacyPolicy/PrivacyPolicy" />
      </Layouts>
    ),
    description: "PrivacyPolicy",
  },
  ContactUs: {
    title: "ContactUs",
    path: "/contactUs",
    element: (
      <Layouts>
        <LazyComponent path="ContactUs/ContactUs" />
      </Layouts>
    ),
    description: "ContactUs",
  },
  ManageProfessional: {
    title: "ManageProfessional",
    path: "/manageProfessional",
    element: (
      <Layouts>
        <LazyComponent path="ManageProfessional/ManageProfessional" />
      </Layouts>
    ),
    description: "ManageProfessional",
  },
  signup: {
    title: "signup",
    path: "/signup",
    element: <LazyComponent path="auth/signup" />,
    description: "signup",
  },
  Register: {
    title: "Register",
    path: "/Register",
    element: <LazyComponent path="auth/Register" />,
    description: "Register",
  },
  login: {
    title: "login",
    path: "/login",
    element: <LazyComponent path="auth/login" />,
    description: "login",
  },
  Forget: {
    title: "forget",
    path: "/forget-password",
    element: <LazyComponent path="auth/forget" />,
    description: "forget",
  },
  reset: {
    title: "Reset Password",
    path: "/reset-password",
    element: <LazyComponent path="auth/resetPassword" />,
    description: "Reset Password",
  },
  // createProfile: {
  //   title: "createProfile",
  //   path: "/createProfile",
  //   element: <LazyComponent path="createProfile/CreateProfile" />,
  //   description: "CreateProfile",
  // },
  upUpcomingEvents: {
    title: "upUpcomingEvents",
    path: "/UpcomingEvents",
    element: <LazyComponent path="UpComingEvents/UpComingEvents" />,
    description: "EventsUpComing",
  },
  createHighlight: {
    title: "createHighlight",
    path: "/createHighlight",
    element: (
      <Layouts>
        <LazyComponent path="FoodDrictory/InspirationOrder/highlightForm" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },

  createSlide: {
    title: "createSlide",
    path: "/createSlide",
    element: (
      <Layouts>
        <LazyComponent path="FoodDrictory/OurSpecials/createSlide" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  createEvent: {
    title: "createEvent",
    path: "/createEvent",
    element: (
      <Layouts>
        <LazyComponent path="home/createEvent" />,
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  // createEmployProfile: {
  //   title: "createEmployProfile",
  //   path: "/createEmployProfile",
  //   element: (
  //     <Layouts>
  //       <LazyComponent path="ProfessionalProfile/createEmployProfile" />
  //     </Layouts>
  //   ),
  //   description: "businessProfile-Page",
  // },
  // createRestaurantProfile: {
  //   title: "createRestaurantProfile",
  //   path: "/createRestaurantProfile",
  //   element: (
  //     <Layouts>
  //       <LazyComponent path="restaurantProfile/createRestaurantProfile" />
  //     </Layouts>
  //   ),
  //   description: "businessProfile-Page",
  // },
  restaurants: {
    title: "Restaurants",
    path: "/restaurants",
    element: (
      // <Layouts>
      <LazyComponent path="restaurantProfile/Restaurants" />
      // </Layouts>
    ),
    description: "restaurants-Page",
  },
  employees: {
    title: "Employee",
    path: "/employees",
    element: (
      // <Layouts>
      <LazyComponent path="employee/Employee" />
      // </Layouts>
    ),
    description: "employees-Page",
  },
  employeeProfile: {
    title: "employeeProfile",
    path: "/employeeProfile",
    element: (
      <Layouts>
        <LazyComponent path="employeeProfile/EmployeProfile" />
      </Layouts>
    ),
    description: "employeeProfile-Page",
  },
  Calendar: {
    title: "calendar",
    path: "/calendar",
    element: (
      <Layouts>
        <LazyComponent path="calendar/calendar" />
      </Layouts>
    ),
    description: "calendar-Page",
  },

  CategoryCards: {
    title: "CategoryDetail",
    path: "/categories/:categoryName",
    element: (
      <Layouts>
        <LazyComponent path="home/Categories/CategoryCards" />
      </Layouts>
    ),
    description: "CategoryCards-Page",
  },
};
