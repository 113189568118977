import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { UserProvider } from "./context/UserContext";
import { FormProvider } from "./context/formRefContext";
import "./i18n";
import { SubscriptionProvider } from "./context/subscriptionContext";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const mode = process.env.REACT_APP_ENV;
const clientId =
  mode === "development"
    ? process.env.REACT_APP_SANDBOX_CLIENT_ID
    : process.env.REACT_APP_LIVE_CLIENT_ID;
    
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PayPalScriptProvider options={{ "client-id": clientId, vault: true }}>
      <FormProvider>
        <UserProvider>
          <SubscriptionProvider>
            <App />
          </SubscriptionProvider>
        </UserProvider>
      </FormProvider>
    </PayPalScriptProvider>
  </React.StrictMode>
);
