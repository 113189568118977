import React, { useEffect } from "react";
// import Layouts from "./layout/Layouts";
// import BoostSection from "./components/home/sectionBoost/SectionBoost";
// import EdgeBusiness from "./components/home/edgeBusinesses/EdgeBusiness";
// import BusinessCard from "./components/BusinessCard/BusinessCard";
// import Home from "./screens/home/home";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";
import { env } from "./utils/env";
import { useTranslation } from "react-i18next";

function App() {
  console.log(env.API_URL);
  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = localStorage.getItem("language") || "en";
    i18n.changeLanguage(lng);
  }, []);
  // console.log(process.env.REACT_APP_LIVE_SUBSCRIPTION_1)
  // console.log(process.env.REACT_APP_LIVE_SUBSCRIPTION_2)
  // console.log(process.env.REACT_APP_LIVE_SUBSCRIPTION_3)
  // console.log(process.env.REACT_APP_SUBSCRIPTION_1)
  // console.log(process.env.REACT_APP_SUBSCRIPTION_2)
  // console.log(process.env.REACT_APP_SUBSCRIPTION_3)
  // console.log(process.env.REACT_APP_SUBSCRIPTION_3)
  // console.log(process.env.REACT_APP_LIVE_API_URL)
  // console.log(process.env.REACT_APP_LOCAL_API_URL)
  // console.log(process.env.REACT_APP_SANDBOX_CLIENT_ID)
  // console.log(process.env.REACT_APP_LIVE_CLIENT_ID)
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
