import React, { createContext, useState, useEffect, useContext } from "react";
import { env } from "../utils/env";
import { UserContext } from "./UserContext";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [subscription, setSubscription] = useState(null);

  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const fetchSubscription = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${env.API_URL}/payment/subscription/${user?.plan?.subscriptionId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          let isValid = data.status === "ACTIVE";

          if (data.status === "SUSPENDED" || data.status === "CANCELED") {
            if (user?.plan?.nextBillingDate) {
              const expirationDate = new Date(
                `${user?.plan?.nextBillingDate}`
              ).toISOString();

              const todayDate = new Date().toISOString();

              isValid = todayDate < expirationDate;
            }
          } else if(data.status === "EXPIRED") isValid = false

          setSubscription({ ...data, isValid });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.plan?.subscriptionId) fetchSubscription();
  }, [user?.plan?.subscriptionId, user?.plan?.nextBillingDate, trigger]);

  return (
    <SubscriptionContext.Provider value={{ loading, subscription, setTrigger }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
